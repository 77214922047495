import React, { useState } from "react";

import { Container } from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";
import Button from "reusecore/Button";
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Alert, Form, Input, Spin }from 'antd'
import ContactWrapper from "./contact.style";

const Contact = () => {
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = ({email}) => {
    setLoading(true)
    addToMailchimp(email)
      .then((data) => {
        setStatus(data.result)
        setMessage(data.msg)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      });
  };

  

  return (
    <ContactWrapper id="contact">
      <SectionTitle className="section-title" UniWidth="55%">
        <h4>Stay Informed</h4>
        <h2>
          <span>Drop us your email address </span> for updates.
        </h2>
      </SectionTitle>
      <Container>
      {status && 
        <Alert
          message={<div dangerouslySetInnerHTML={{ __html: message }} />}
          type={status}
          closable
          style={{marginTop: 12}}
        />
        }
        <Form layout="inline" onFinish={handleSubmit}>
          <Form.Item
            name="email"
            rules={[{ type: 'email', required: true, message: 'Enter your Email!' }]}
          >
            <Input placeholder="Enter your Email" style={{width: '300px'}}/>
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ type: 'email', required: true, message: 'Enter your Email!' }]}
          >
            <Spin spinning={loading}><Button className='button'>Sign Up</Button></Spin>
          </Form.Item>
          <Form.Item>

          </Form.Item>
        </Form>

      </Container>
    </ContactWrapper>
  );
};

export default Contact;
